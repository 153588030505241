var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100",staticStyle:{"margin-top":"70px"}},[_vm._m(0),_c('div',{staticClass:"authincation h-100"},[_c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"row justify-content-center h-100 align-items-center"},[_c('div',{staticClass:"col-md-5 col-sm-6 col-xs-6"},[_c('div',{staticClass:"authincation-content"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"auth-form"},[_c('LoaderComponent',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]}),_c('h4',{staticClass:"text-center mb-4 text-white"},[_vm._v(" Sign in your account ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.loginUser.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.email),expression:"auth.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":"hello@example.com"},domProps:{"value":(_vm.auth.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "email", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.auth.password),expression:"auth.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.auth.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.auth, "password", $event.target.value)}}})]),_vm._m(3),_vm._m(4)]),_vm._m(5)],1)])])])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-3"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"images/logos/SmartMedicare.png","alt":"","width":"400px"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-1 text-white"},[_c('strong',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mb-1 text-white"},[_c('strong',[_vm._v("Password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row d-flex justify-content-between mt-4 mb-2"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-checkbox ml-1 text-white"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"basic_checkbox_1"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"basic_checkbox_1"}},[_vm._v("Remember my preference")])])]),_c('div',{staticClass:"form-group"},[_c('a',{staticClass:"text-white",attrs:{"href":"page-forgot-password.html"}},[_vm._v("Forgot Password?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn bg-white text-success btn-block",attrs:{"type":"submit"}},[_vm._v(" Sign Me In ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-account mt-3"},[_c('p',{staticClass:"text-white"},[_vm._v(" Don't have an account? "),_c('a',{staticClass:"text-white",attrs:{"href":"#"}},[_vm._v("Sign up")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"copyright"},[_c('p',[_vm._v(" Copyright © Designed & Developed by "),_c('a',{attrs:{"href":"https://uniccongroup.com/","target":"_blank"}},[_vm._v("Uniccon")]),_vm._v(" 2022 ")])])])
}]

export { render, staticRenderFns }