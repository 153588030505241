<template>

  <div class="container p-0">
      <div class="row">
          <div class="col-md-10 col-xl-8 mx-auto">
              <h1 class="text-center price-h text-black font-w600">Find the <span>Right Plan</span> for your <br>
                  HealthCare</h1>
              <p class="lead text-center mb-4 fs-20 text-black mb-0">Whether you're a business or an individual,
                  14-day trial no credit card
                  required.
              </p>

              <div class="row justify-content-center mt-3 mb-2">
                  <div class="col-auto">
                      <nav class="nav btn-group">
                          <a href="#monthly" class="btn btn-outline-primary active" data-toggle="tab">Monthly
                              billing</a>
                          <a href="#annual" class="btn btn-outline-primary annual-tab" data-toggle="tab">Annual
                              billing</a>
                      </nav>
                  </div>
              </div>

              <div class="tab-content">
                  <!-- monnthly starts here -->
                  <div class="tab-pane fade show active" id="monthly">
                      <div class="row py-4">


                          <div v-for="(plan, index) in monthlyPlans" :key="index" class="col-sm-4 mb-3 mb-md-0">
                              <div class="card text-center h-100">
                                  <div class="card-body d-flex flex-column">
                                      <!-- Card content -->
                                      <div class="mb-4">
                                          <!-- Plan details -->
                                          <h5>{{ plan.name }}</h5>
                                          <span class="fs-34 font-w600">{{ plan.amount }}</span>
                                          <span>/mo</span>
                                      </div>
                                      <!-- Includes -->
                                      <h6>Includes:</h6>
                                      <table class="table">
                                          <tbody>
                                              <!-- Plan features -->
                                              <tr v-for="(feature, key) in plan.features" :key="key">
                                                  <td>{{ key }}</td>
                                                  <td>{{ feature }}</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <!-- Paystack checkout button -->
                                      <Paystack ref="paystack" :amount="plan.amount * 100" :options="paystackOptions"
                                          @success="paymentSuccessful" @close="paymentClosed">
                                          <button class="btn btn-lg btn-outline-primary"
                                              @click="initiatePayment('monthly', plan.amount)">
                                              Pay Now
                                          </button>
                                      </Paystack>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- monthly ends here -->

                  <!-- annual starts here -->
                  <div class="tab-pane fade" id="annual">
                      <div class="row py-4">
                          <div class="col-sm-4 mb-3 mb-md-0">
                              <div class="card text-center h-100">
                                  <div class="card-body d-flex flex-column">
                                      <div class="mb-4">
                                          <h5>Standard</h5>
                                          <span class="fs-34  font-w600">₦ 268,500</span>
                                          <span>/mo</span>
                                      </div>
                                      <h6>Includes:</h6>
                                      <table class="table">
                                          <tbody>
                                              <tr>
                                                  <td>Staff Strength</td>
                                                  <td>1 - 50</td>
                                              </tr>
                                              <tr>
                                                  <td>Patient Capacity</td>
                                                  <td>10,000</td>
                                              </tr>
                                              <tr>
                                                  <td>Amount
                                                      ( One-Off) </td>
                                                  <td>1,300,000</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <div class="mt-auto">
                                          <a href="#" class="btn btn-lg btn-outline-primary">Sign up</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-4 mb-3 mb-md-0">
                              <div class="card text-center h-100">
                                  <div class="card-body d-flex flex-column">
                                      <div class="mb-4">
                                          <h5>Platinum</h5>
                                          <span class="fs-34  font-w600">₦ 268,500</span>
                                          <span>/mo</span>
                                      </div>
                                      <h6>Includes:</h6>
                                      <table class="table">
                                          <tbody>
                                              <tr>
                                                  <td>Staff Strength</td>
                                                  <td>1 - 50</td>
                                              </tr>
                                              <tr>
                                                  <td>Patient Capacity</td>
                                                  <td>10,000</td>
                                              </tr>
                                              <tr>
                                                  <td>Amount
                                                      ( One-Off) </td>
                                                  <td>1,300,000</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <div class="mt-auto">
                                          <a href="#" class="btn btn-lg btn-primary">Try it for free</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-4 mb-3 mb-md-0">
                              <div class="card text-center h-100">
                                  <div class="card-body d-flex flex-column">
                                      <div class="mb-4">
                                          <h5>Platinum</h5>
                                          <span class="fs-34  font-w600">₦ 268,500</span>
                                          <span>/mo</span>
                                      </div>
                                  </div>
                                  <h6>Includes:</h6>
                                  <table class="table">
                                      <tbody>
                                          <tr>
                                              <td>Staff Strength</td>
                                              <td>1 - 50</td>
                                          </tr>
                                          <tr>
                                              <td>Patient Capacity</td>
                                              <td>10,000</td>
                                          </tr>
                                          <tr>
                                              <td>Amount
                                                  ( One-Off) </td>
                                              <td>1,300,000</td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="mt-auto">
                                      <a href="#" class="btn btn-lg btn-outline-primary">Try it for free</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div><!-- annual ends here -->

              </div>




          </div>

          <hr>


      </div>
  </div>


</template>


<script>

import paystack from 'vue-paystack';
export default {

  components: {
      Paystack: paystack,
  },

  data() {
      return {
          paystackOptions: {
              email: 'user@example.com', // Replace with the customer's email
              amount: 0, // Will be updated dynamically
              currency: 'NGN', // Nigerian Naira, you can change as needed
              metadata: {
                  custom_fields: [
                      // Add any custom metadata fields if needed
                  ]
              },
          },
          key: 'sk_live_a3cb27f3d8df07fa6ce5d26c7c218e71582d06bc',
          monthlyPlans: [
              {
                  name: 'Standard',
                  amount: '₦ 118500',
                  features: {
                      'Staff Strength': '1 - 50',
                      'Patient Capacity': '10,000',
                      'AI Integration': 'Inclusive'
                  }
              },
              {
                  name: 'Gold',
                  amount: '₦ 201800',
                  features: {
                      'Staff Strength': '51 - 150',
                      'Patient Capacity': '20,000',
                      'AI Integration': 'Inclusive'
                  }
              },
              {
                  name: 'Platinum',
                  amount: '₦ 201,800',
                  features: {
                      'Staff Strength': '151 - 500',
                      'Patient Capacity': '40,000',
                      'AI Integration': 'Inclusive'
                  }
              }
              // Add other monthly plans here
          ],
          yearlyPlans: [
              {
                  name: 'Standard Yearly',
                  amount: '₦ 1,422,000',
                  features: {
                      'Staff Strength': '1 - 50',
                      'Patient Capacity': '10,000',
                      'Amount (One-Off)': '₦ 1,300,000'
                  }
              },
              // Add other yearly plans here
          ],
      };
  },
  methods: {
  initiatePayment(type, amount) {
    // Update paystackOptions with the new amount
    this.paystackOptions.amount = amount * 100; // Convert amount to kobo
    // Call the Paystack component method to open the payment modal
    this.$refs.paystack.startPayment();
  },

  paymentSuccessful(response) {
  // Handle successful payment here
  console.log('Payment successful', response);

  // Send data to backend
  axios.post('https://api.smartmedicares.com/api/store-payment', {
    staff_id: this.staffId,
    reference: response.reference,
    amount: response.amount,
    status: response.status,
    plan: response.plan,
    expires_at: response.expires_at,
    // Include any other data you need to send
  })
  .then(response => {
    console.log('Data sent to backend:', response.data);
    // Optionally, you can perform additional actions here
  })
  .catch(error => {
    console.error('Error sending data to backend:', error);
    // Handle errors here
  });
},

  paymentClosed() {
    // Handle payment modal closed here
    console.log('Payment closed');
  },
},
};
</script>


<style>
body {
  margin-top: 20px;
  background-color: #fcfcfc;
}

h1 {

  font-weight: 900;
  font-family: 'poppins', sans-serif;
  size: 52px;
  line-height: 62.4px;
  text-align: center;
  color: #001D42;
}

.price-h {
  margin: 70px;
}

.card {
  margin-bottom: 1.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .025);
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e5e9f2;
  border-radius: .2rem;
}

span {
  color: #31B473;
}

button {
  background-color: #007A64 !important;
  color: #fff !important;
  border-color: #007A64 !important;
  border-radius: 20px!important;
  border-color: transparent!important;

}


/* Style for the active tab */
.nav .btn-outline-primary.active,
.nav .btn-outline-primary:focus,
.nav .btn-outline-primary:hover {
  background-color: rgb(2, 122, 100) !important;
  color: white !important;
  border-color: rgb(2, 122, 100) !important;
}

/* Style for the inactive tabs */
.nav .btn-outline-primary:not(.active):not(:focus):not(:hover) {
  background-color: #bd9b16 !important;
  color: white !important;
  border-color: #bd9b16 !important;
}


.table {
  border-collapse: collapse;
}

.table td,
.table th {
  border: none;
  padding: .75rem;
  text-align: center;
}
</style>
