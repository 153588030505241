var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"content-body"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"form-head d-flex align-items-center mb-sm-4 mb-3"},[_c('div',{staticClass:"mr-auto"},[_c('h2',{staticClass:"text-black font-w600"},[_vm._v(" "+_vm._s(_vm.$selected_facility.title)+" Facility ")]),_c('p',{staticClass:"mb-0"},[_vm._v("Welcome back, "+_vm._s(_vm.$user.firstname))])]),_c('div',{staticClass:"dropdown ml-auto mr-2"},[_vm._m(0),_c('ul',{staticClass:"dropdown-menu dropdown-menu-right"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('li',{staticClass:"dropdown-item"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-stock",modifiers:{"sidebar-stock":true}}],staticClass:"text-dark"},[_vm._m(4),_vm._v(" Add Stock ")])]),_c('li',{staticClass:"dropdown-item"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-dispense",modifiers:{"sidebar-dispense":true}}],staticClass:"text-dark"},[_vm._m(5),_vm._v(" Dispense Drugs ")])]),_vm._m(6)])])]),(_vm.role == 'Doctor')?_c('Doctor',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Nurse')?_c('Nurse',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Laboratory')?_c('Nurse',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Pharmacy')?_c('Pharmacist',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Accountant')?_c('Accountant',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Reception' || _vm.role == 'Records')?_c('Receptionist',{attrs:{"metrics":_vm.metrics}}):_vm._e(),(_vm.role == 'Driver')?_c('Driver'):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('VisitsList')],1),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-success light sharp",attrs:{"href":"#","data-toggle":"dropdown","aria-expanded":"true"}},[_vm._v(" Quick Links "),_c('i',{staticClass:"las la-angle-down ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown-item"},[_c('span',{attrs:{"role":"button"}},[_c('a',{attrs:{"href":"/add-patient"}},[_c('i',{staticClass:"las la-user-injured text-success mr-2"}),_c('span',{staticClass:"text-dark"},[_vm._v(" Register Patient ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown-item"},[_c('span',{attrs:{"role":"button"}},[_c('a',{attrs:{"href":"/patients/all"}},[_c('i',{staticClass:"las la-user-injured text-success mr-2"}),_c('span',{staticClass:"text-dark"},[_vm._v("All Patients")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown-item"},[_c('span',{attrs:{"role":"button"}},[_c('a',{attrs:{"href":"/emergency-calls"}},[_c('i',{staticClass:"las la-phone-volume text-success mr-2"}),_c('span',{staticClass:"text-dark"},[_vm._v(" Emergency Calls")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void()","data-toggle":"tooltip","data-placement":"top","title":"Export"}},[_c('i',{staticClass:"las la-store text-success mr-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void()","data-toggle":"tooltip","data-placement":"top","title":"Export"}},[_c('i',{staticClass:"las la-prescription-bottle-alt text-success mr-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"dropdown-item"},[_c('span',{staticClass:"text-dark",attrs:{"role":"button"}},[_c('a',{attrs:{"href":"/vaccinate-patients"}},[_c('i',{staticClass:"las la-syringe text-success mr-2"})]),_vm._v(" Vaccinations ")])])
}]

export { render, staticRenderFns }