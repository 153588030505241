var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deznav"},[_c('div',{staticClass:"deznav-scroll"},[_c('ul',{staticClass:"metismenu",attrs:{"id":"menu"}},[_c('li',[_c('a',{attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"las la-signal la-2X",staticStyle:{"visibility":"hidden"}}),_c('toggle-button',{attrs:{"disabled":"","color":"#097969","width":"60","height":"29"},model:{value:(_vm.$store.state.mode),callback:function ($$v) {_vm.$set(_vm.$store.state, "mode", $$v)},expression:"$store.state.mode"}})],1)]),_c('li',[_vm._m(0),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[(_vm.$user_type == 'admin')?_c('a',{attrs:{"href":"/admin-dashboard"}},[_vm._v("Admin")]):_vm._e()]),_c('li',[(_vm.$user_type == 'staff')?_c('a',{attrs:{"href":"/dashboard"}},[_vm._v("Dashboard")]):_vm._e()]),_vm._m(1)])]),_c('li',[_vm._m(2),_c('ul',{attrs:{"aria-expanded":"false"}},[_vm._m(3),_vm._l((_vm.patient_lists),function(patient){return _c('li',{key:patient.id},[_c('a',{attrs:{"href":`/patients-${patient.type}`}},[_vm._v(_vm._s(patient.name)+" ")])])}),_vm._m(4)],2)]),(_vm.$user_type == 'admin')?_c('li',[_vm._m(5),_vm._m(6)]):_vm._e(),(_vm.$user_type == 'admin' ||
        _vm.$user.job_title.name == 'Cashier' ||
        _vm.$user.job_title.name == 'Accountant'
        )?_c('li',[_vm._m(7),_vm._m(8)]):_vm._e(),_c('li',[_vm._m(9),_c('ul',{attrs:{"aria-expanded":"false"}},[(_vm.$user_type == 'admin')?_c('li',[_c('a',{attrs:{"href":"/manage-departments"}},[_vm._v("Manage Departments")])]):_vm._e(),_vm._l((_vm.departments.data),function(dept){return _c('li',{key:dept.id},[_c('a',{attrs:{"href":`/department-${dept.id}`}},[_vm._v(" "+_vm._s(dept.name)+" ")])])})],2)]),_c('li',[_vm._m(10),_c('ul',{attrs:{"aria-expanded":"false"}},[(_vm.$user_type == 'admin')?_c('li',[_c('a',{attrs:{"href":`/manage-services/Services`}},[_c('i',{staticClass:"las la-file-invoice-dollar mr-2"}),_vm._v(" Service Fees")])]):_vm._e(),(_vm.$user_type == 'admin')?_c('li',[_vm._m(11)]):_vm._e(),(_vm.$user_type == 'admin')?_c('li',[_vm._m(12)]):_vm._e(),_vm._m(13),_vm._m(14)])]),_vm._m(15),(_vm.$user_type == 'admin' || _vm.$user.job_title.name == 'Inventory')?_c('li',[_vm._m(16),_vm._m(17)]):_vm._e(),(_vm.$user_type == 'admin' || _vm.$user.job_title.name == 'Pharmacy')?_c('li',[_vm._m(18),_vm._m(19)]):_vm._e(),(_vm.$user_type == 'admin')?_c('li',[_vm._m(20),_vm._m(21)]):_vm._e(),_vm._m(22),_vm._m(23),_vm._m(24),_c('li',[_vm._m(25),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[(_vm.$user_type == 'admin')?_c('a',{attrs:{"href":"#"}},[_vm._v("Hospital Settings")]):_vm._e()]),_vm._m(26)])]),_vm._m(27)]),_vm._m(28)]),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"flaticon-381-networking"}),_c('span',{staticClass:"nav-text"},[_vm._v("My Account")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/settings"}},[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-user-injured la-5x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Patients ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/add-patient"}},[_vm._v(" Add Patient ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/all-records"}},[_vm._v("All Records")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-user-friends la-2x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Staff ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/all-staffs"}},[_vm._v("Manage Staff")])]),_c('li',[_c('a',{attrs:{"href":"/manage-roles"}},[_vm._v("Roles Manager")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"mdi mdi-currency-ngn"}),_c('span',{staticClass:"nav-text"},[_vm._v("Finance & Billing ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/finance-dashboard"}},[_vm._v("Dashboard")])]),_c('li',[_c('a',{attrs:{"href":"/manage-payments"}},[_vm._v("Expenses/Payments")])]),_c('li',[_c('a',{attrs:{"href":"/employee-payroll"}},[_vm._v("Employee Payroll")])]),_c('li',[_c('a',{attrs:{"href":"/manage-bills"}},[_vm._v("Bills")])]),_c('li',[_c('a',{attrs:{"href":"manage-services"}},[_vm._v("Services")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"mdi mdi-folder"}),_c('span',{staticClass:"nav-text"},[_vm._v("Departments ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-prescription-bottle la-2x"}),_vm._v(" "),_c('span',{staticClass:"nav-text"},[_vm._v("Services & Drugs ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/manage-services/Drugs"}},[_c('i',{staticClass:"las la-tablets mr-2"}),_vm._v(" Drugs & Fees")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/manage-diseases"}},[_c('i',{staticClass:"las la-medkit mr-2"}),_vm._v(" Health Conditions")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/manage-ambulance"}},[_c('i',{staticClass:"las la-ambulance mr-2"}),_vm._v(" Ambulance")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/emergency-calls"}},[_c('i',{staticClass:"las la-phone-volume mr-2"}),_vm._v(" Emergency Calls")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-file-medical"}),_c('span',{staticClass:"nav-text"},[_vm._v("EMR")])]),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/all-records"}},[_vm._v("Facility Records")])]),_c('li',[_c('a',{attrs:{"href":"/blood-records"}},[_vm._v("Blood Records")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Laboratory Records")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("General Records")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Hospitalizations")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-clipboard la-2x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Inventory Management ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/inventory-dashboard"}},[_vm._v("Dashboard")])]),_c('li',[_c('a',{attrs:{"href":"/manage-inventories"}},[_vm._v("Inventories")])]),_c('li',[_c('a',{attrs:{"href":"/inventory-stocks"}},[_vm._v("Stocks")])]),_c('li',[_c('a',{attrs:{"href":"/suppliers"}},[_vm._v("Suppliers")])]),_c('li',[_c('a',{attrs:{"href":"/purchase-inventory"}},[_vm._v("Purchase/Receive Inventory")])]),_c('li',[_c('a',{attrs:{"href":"/inventory-transfers"}},[_vm._v("Transfer Stock")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-prescription la-3x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Pharmacy Management ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/pharmacy-dashboard"}},[_vm._v("Dashboard")])]),_c('li',[_c('a',{attrs:{"href":"/manage-pharmacy"}},[_vm._v("Drugs & Dispensaries ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-procedures la-3x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Ward Manager ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/manage-wards"}},[_vm._v("Manage Wards")])]),_c('li',[_c('a',{attrs:{"href":"/manage-beds"}},[_vm._v("Manage Beds")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-syringe"}),_c('span',{staticClass:"nav-text"},[_vm._v("Vaccinations")])]),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/manage-vaccines"}},[_vm._v("Manage Vaccinations")])]),_c('li',[_c('a',{attrs:{"href":"/vaccinate-patients"}},[_vm._v("Vaccinate Patients")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"mdi mdi-monitor"}),_c('span',{staticClass:"nav-text"},[_vm._v("Front Desk")])]),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/emergency-calls"}},[_vm._v("Emergency Calls")])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Visitors")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-tint"}),_c('span',{staticClass:"nav-text"},[_vm._v("Blood Bank ")])]),_c('ul',{attrs:{"aria-expanded":"false"}},[_c('li',[_c('a',{attrs:{"href":"/blood-bank"}},[_vm._v("Blood Banks")])]),_c('li',[_c('a',{attrs:{"href":"/donor"}},[_vm._v("Blood Donors")])]),_c('li',[_c('a',{attrs:{"href":"/blood-donations"}},[_vm._v("Blood Donations")])]),_c('li',[_c('a',{attrs:{"href":"/blood-out"}},[_vm._v("Blood Issued Out")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-arrow ai-icon",attrs:{"href":"javascript:void()","aria-expanded":"false"}},[_c('i',{staticClass:"las la-cog la-2x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Notice Board")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://smartmedicares.com/#/contact-us","aria-expanded":"false"}},[_c('i',{staticClass:"las la-comment la-2x"}),_c('span',{staticClass:"nav-text"},[_vm._v("Feedback")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_c('strong',[_vm._v("Uniccon SmartMedicare")]),_vm._v(" © 2024 All Rights Reserved")]),_c('p',[_vm._v("Powered by Uniccon Group")])])
}]

export { render, staticRenderFns }