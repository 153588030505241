var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-row float-right"},[_c('div',{staticClass:"form-group mr-2"},[_c('label',{attrs:{"for":""}},[_vm._v("From")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.from),expression:"from"}],staticClass:"form-control input-rounded",attrs:{"type":"date","placeholder":""},domProps:{"value":(_vm.from)},on:{"input":function($event){if($event.target.composing)return;_vm.from=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("To")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.to),expression:"to"}],staticClass:"form-control input-rounded",attrs:{"type":"date","placeholder":""},domProps:{"value":(_vm.to)},on:{"change":_vm.getVisits,"input":function($event){if($event.target.composing)return;_vm.to=$event.target.value}}})])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-4 pb-4"},[_c('p',{staticClass:"card-title h5"},[_vm._v(" "+_vm._s(_vm.visits.total)+" Patient Visits")]),_c('download-excel',{staticClass:"btn btn-success",attrs:{"data":_vm.visits.data,"fields":_vm.json_fields,"type":"xls","escapeCsv":false,"name":'Visits'}})],1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-responsive-md"},[_vm._m(0),_c('tbody',_vm._l((_vm.visits.data),function(visit,index){return _c('tr',{key:visit.id},[_c('td',[_c('strong',[_vm._v(_vm._s(index + 1)+" ")])]),_c('td',[_vm._v(_vm._s(visit.patient.full_name))]),_c('td',[_vm._v(_vm._s(visit.patient.card_no))]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(visit.created_at,"MMMM Do YYYY, h:mm:ss a"))+" ")]),_c('td',[_c('a',{class:visit.finished == 1
                          ? 'btn btn-success btn-sm px-3 light ml-2'
                          : 'btn btn-primary btn-sm px-3 light ml-2',attrs:{"href":"javascript:void()"}},[_vm._v(" "+_vm._s(visit.status)+" ")])]),_c('td',[_c('span',[(visit.finished != 1)?_c('a',{staticClass:"mr-2 btn-sm btn btn-success",on:{"click":function($event){return _vm.updateVisit(visit)}}},[_vm._v(" end visit ")]):_vm._e()])])])}),0)])]),_c('nav',[_c('ul',{staticClass:"pagination pagination-gutter"},[_vm._m(1),_vm._l((_vm.visits.last_page),function(link){return _c('li',{key:link,class:_vm.visits.current_page == link
                    ? 'page-item active'
                    : 'page-item'},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void()"},on:{"click":function($event){return _vm.getVisits(link)}}},[_vm._v(_vm._s(link)+" ")])])}),_vm._m(2)],2)])])])])]),_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","can-cancel":true,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('strong',[_vm._v("#")])]),_c('th',[_c('strong',[_vm._v("Patient")])]),_c('th',[_c('strong',[_vm._v("Hospital No.")])]),_c('th',[_c('strong',[_vm._v("Date")])]),_c('th',[_c('strong',[_vm._v("Status")])]),_c('th',[_c('strong',[_vm._v("Action")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"page-item page-indicator"},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"la la-angle-left"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"page-item page-indicator"},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:void()"}},[_c('i',{staticClass:"la la-angle-right"})])])
}]

export { render, staticRenderFns }